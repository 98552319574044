import { StringObject } from '@intelliarts/ia-react-template';

import users from '../../components/User/locales/usersLocale-en';
import accessCards from '../../components/AccessCards/locales/accessCardsLocale-en';

const localizationEN: StringObject = {
  template: {
    src: {
      components: {
        layout: {
          header: {
            appName: 'Users'
          }
        },
        dialog: {
          cancelButton: 'Cancel'
        },
        error: {
          default: {
            defaultErrorMessage: 'Ops, we\'ve got an error'
          }
        }
      }
    }
  },
  /********************************************/
  src: {
    components: {
      pages: {
        settingsPage: {
          language: {
            heading: 'Language',
            options: {
              uk: 'UA',
              en: 'EN'
            }
          }
        },
        notFoundPage: {
          message: 'Page not found..'
        },
      },
      users,
      accessCards,
    },
    /********************************************/
    config: {
      routesConfigs: {
        notFoundPageConfig: {
          name: 'Not Found Page'
        },
        settingsPageConfig: {
          name: 'Settings',
          label: 'Settings'
        },
      },
    },
    /********************************************/
  }
};

export default localizationEN;
