import { UserName, User } from './User';

export type UserView = Omit<User, 'googleId' | 'name' | 'phones' | 'gender' | 'isGoogleUser'> & UserName;

export enum UserViewFields {
  id = 'id',
  givenName = 'givenName',
  familyName = 'familyName',
  email = 'email',
  fullName = 'fullName',
  photo = 'photo',
  isRemote = 'isRemote',
  isArchived = 'isArchived',
  isServiceAccount = 'isServiceAccount'
}
