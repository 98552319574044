import { UsersPageConfig } from '../components/User/configs/usersPageConfig';
import { AccessCardsPageConfig } from '../components/AccessCards/configs/accessCardsPageConfig';
import { SettingsPageConfig } from './routesConfigs/settingsPageConfig';
import { NotFoundPageConfig } from './routesConfigs/notFoundPageConfig';


const routesConfig = [
  UsersPageConfig,
  AccessCardsPageConfig,
  SettingsPageConfig,
  NotFoundPageConfig,
];

export default routesConfig;
