import { lazy } from 'react';

import { MenuItemConfig, RestPageConfig } from '@intelliarts/ia-react-template';

import authConfig, { NO_NAME_ADMIN_MENU_GROUP } from '../../../config/authConfig';
import { CARD_LOCALE_PATH } from '../locales';
import { accessCardsDao } from './AccessCardsDao';
import { accessCardFormDialogConfig } from './accessCardFormDialogConfig';
import { AccessCardView } from '../models/AccessCard';
import { ACCESS_CARDS_PATH, AccessCardsStore } from '../store/accessCardsStore';
import { UsersStore } from '../../User/store/usersStore';

export const AccessCardsPageConfig: RestPageConfig<AccessCardView> = {
  dao: accessCardsDao,
  formConfig: accessCardFormDialogConfig,
  name: `${CARD_LOCALE_PATH}.pageConfig.name`,
  path: ACCESS_CARDS_PATH,
  allowedRoles: [ authConfig.roles.ADMIN ],
  List: lazy(() => import('../components/AccessCardsList')),
  Details: lazy(() => import('../../pages/NotFoundPage')),
  storeDependencies: [
    { store: AccessCardsStore },
    { store: UsersStore },
  ],
  menuItemConfig: new MenuItemConfig({
    icon: lazy(() => import('@mui/icons-material/CardMembership')),
    label: `${CARD_LOCALE_PATH}.pageConfig.label`,
    group: NO_NAME_ADMIN_MENU_GROUP
  }),
};
