import { AccessCardViewFields } from '../models/AccessCard';

export default {
  pageConfig: {
    name: 'Перепустки',
    label: 'Перепустки',
  },
  edit: {
    notFound: 'Користувача не знайдено!',
    cardRequired: 'Обов\'язкове поле',
    cardExists: 'Картка з таким айді вже існує'
  },
  accessCardsList: {
    columns: {
      [AccessCardViewFields.id]: {
        headerName: 'Айді картки'
      },
      [AccessCardViewFields.userId]: {
        headerName: 'Айді користувача'
      },
      [AccessCardViewFields.fullName]: {
        headerName: 'Прізвище та ім\'я'
      },
      [AccessCardViewFields.comment]: {
        headerName: 'Коментар'
      },
      [AccessCardViewFields.isActive]: {
        headerName: 'Активна'
      },
      [AccessCardViewFields.isReportingEnabled]: {
        headerName: 'Автозвітування'
      },
      [AccessCardViewFields.editAccessCard]: {
        tooltipText: 'Оновити дані картки для {{fullName}}'
      },
      [AccessCardViewFields.deleteAccessCard]: {
        tooltipText: 'Видалити картку для {{fullName}}'
      },
    },
    toolbar: {
      displayArchivedAccessCardsToggle: {
        label: 'Показати у т.ч. неактивні картки',
      },
      openMenuTooltipTitle: 'Відкрити меню',
    },
  },
};
