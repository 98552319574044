import { AccessCardViewFields } from '../models/AccessCard';

export default {
  pageConfig: {
    name: 'Access Cards',
    label: 'Access Cards',
  },
  edit: {
    notFound: 'Not found!',
    cardRequired: 'Required field',
    cardExists: 'Access Card with this id already exists'
  },
  accessCardsList: {
    columns: {
      [AccessCardViewFields.id]: {
        headerName: 'Card ID'
      },
      [AccessCardViewFields.userId]: {
        headerName: 'User ID'
      },
      [AccessCardViewFields.fullName]: {
        headerName: 'Family and Given Name'
      },
      [AccessCardViewFields.comment]: {
        headerName: 'Comment'
      },
      [AccessCardViewFields.isActive]: {
        headerName: 'Active'
      },
      [AccessCardViewFields.isReportingEnabled]: {
        headerName: 'Enable Autoreporting'
      },
      [AccessCardViewFields.editAccessCard]: {
        tooltipText: 'Update access card for {{fullName}}'
      },
      [AccessCardViewFields.deleteAccessCard]: {
        tooltipText: 'Delete card for {{fullName}}'
      },
    },
    toolbar: {
      displayArchivedAccessCardsToggle: {
        label: 'Show also archived access cards',
      },
      openMenuTooltipTitle: 'Open menu',
    },
  },
};
