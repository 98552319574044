import { DaoModel } from '@intelliarts/ia-react-template';

import { AccessCardsStore } from '../store/accessCardsStore';
import { useAccessCardsViews } from '../store/accessCardsSelectors';
import { AccessCardView } from '../models/AccessCard';

export class AccessCardsDao extends DaoModel<AccessCardView> {
  constructor() {
    super({
      modelName: 'recipientBankDetails',
      Store: AccessCardsStore,
    });
  }

  findByCode(code: string) {
    //TMP: initial semi-dummy implementation. The method has not been used yet
    return this.getAll().find(ac => ac.id === code) || null;
  }

  getAll() {
    //TMP: initial semi-dummy implementation. The method has not been used yet
    return useAccessCardsViews();
  }

}

export const accessCardsDao = new AccessCardsDao();
