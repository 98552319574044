import React from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { TextField } from '@intelliarts/ia-react-template';

import { AccessCardViewFields } from '../models/AccessCard';
import { useUsersView } from '../../User/store/usersSelectors';
import { CARD_LIST_LOCALE_PATH, CARD_LOCALE_PATH } from '../locales';


export const UserSelectedField = () => {
  const users = useUsersView();
  const { t } = useTranslation()

  const formProps = useFormState({ subscription: { values: true } });
  const userId = Number(formProps.values.userId)
  const userName = users.find(user => user.id === userId)?.fullName;

  return <TextField
    name={AccessCardViewFields.fullName}
    label={userId ? '' : `${CARD_LIST_LOCALE_PATH}.columns.${AccessCardViewFields.fullName}.headerName`}
    value={userName ? userName : userId ? t(`${CARD_LOCALE_PATH}.edit.notFound`) : ''}
    disabled={true}
    required={false}/>
}
