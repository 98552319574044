import { lazy } from 'react';

import { MenuItemConfig, ReactPageConfig } from '@intelliarts/ia-react-template';

import authConfig, { NO_NAME_ADMIN_MENU_GROUP } from '../../../config/authConfig';
import { UsersStore } from '../store/usersStore';
import { USERS_LOCALE_PATH } from '../locales';

export const UsersRoutes = {
  default: 'users',
};

export const UsersPageConfig: ReactPageConfig = {
  name: `${USERS_LOCALE_PATH}.pageConfig.name`,
  path: UsersRoutes.default,
  allowedRoles: authConfig.roles.ALL,
  component: lazy(() => import('../components/UsersPage')),
  storeDependencies: [
    { store: UsersStore },
  ],
  menuItemConfig: new MenuItemConfig({
    icon: lazy(() => import('@mui/icons-material/PeopleAlt')),
    label: `${USERS_LOCALE_PATH}.pageConfig.label`,
    group: NO_NAME_ADMIN_MENU_GROUP
  })
};
