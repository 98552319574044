import { FormConfig } from '@intelliarts/ia-react-template';

import { AccessCardViewFields, AccessCardView } from '../models/AccessCard';
import { UserSelectedField } from '../components/UserSelectedField';
import { CardIdField } from '../components/CardIdField';
import { CARD_LIST_LOCALE_PATH } from '../locales';

const getKey = (name: string) => `${CARD_LIST_LOCALE_PATH}.columns.${name}.headerName`;

export const accessCardFormDialogConfig: FormConfig<AccessCardView> = {
  formFields: [
    CardIdField,
    {
      name: AccessCardViewFields.userId,
      type: 'number',
      label: getKey(AccessCardViewFields.userId),
      required: false,
    },
    UserSelectedField,
    {
      name: AccessCardViewFields.comment,
      label: getKey(AccessCardViewFields.comment),
      required: false,
    },
  ],
};


