import React from 'react';

import { theme } from '@intelliarts/react-components';
import { App, patchAxiosConfig } from '@intelliarts/ia-react-template';

import config from './config';

patchAxiosConfig({ baseURL: '/api/', timeout: 60000 }); // timeout 60000 = 1 minute

export default function UsersApp() {
  return (
    <App config={config} theme={theme} />
  );
}
