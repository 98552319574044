import { lazy } from 'react';

import { ReactPageConfig } from '@intelliarts/ia-react-template';

import authConfig from '../authConfig';

export const NotFoundPageConfig: ReactPageConfig = {
  name: 'src.config.routesConfigs.notFoundPageConfig.name',
  path: 'not-found-page',
  component: lazy(() => import('../../components/pages/NotFoundPage')),
  allowedRoles: authConfig.roles.ALL,
  storeDependencies: [],
  menuItemConfig: null
};
