import { LocalesType } from '@intelliarts/ia-react-template';

import appLocalizationUA from './ua/localizationUA';
import appLocalizationEN from './en/localizationEN';

export const locales: LocalesType = {
  uk: {
    translation: appLocalizationUA
  },
  en: {
    translation: appLocalizationEN
  }
};
