import { createActionsHook } from 'react-sweet-state';

import { createDataHook, createLoadingHook } from '@intelliarts/ia-react-template';

import { UserView } from '../models/UserView';
import { areEntitiesEmpty, useActionWithSuccessMessage } from '../../../utils';
import { UsersStore } from './usersStore';

const getUsersView = (users: UserView[]): UserView[] => {
  if (areEntitiesEmpty(users)) return [];
  return users;
};

export const useUsersView = createDataHook(UsersStore, getUsersView);

export const useUsersIsLoading = createLoadingHook(UsersStore);

export const useUsersActions = createActionsHook(UsersStore);

export const useUsersEditAction = () => {
  return useActionWithSuccessMessage<UserView>(useUsersActions);
};
