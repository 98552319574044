import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, Validator } from '@intelliarts/ia-react-template';

import { useAccessCardsViews } from '../store/accessCardsSelectors';
import { AccessCardViewFields } from '../models/AccessCard';
import { CARD_LIST_LOCALE_PATH, CARD_LOCALE_PATH } from '../locales';


export const CardIdField = () => {
  const { t } = useTranslation();
  const accessCardViews = useAccessCardsViews();

  const validateIfCardIdExists: Validator = (value, t) => {
    if (!value) {
      return {
        isValid: false,
        error: t(`${CARD_LOCALE_PATH}.edit.cardRequired`),
      }
    }
    return {
      isValid: !!value && !accessCardViews.find(({ id }) => value === id),
      error: t(`${CARD_LOCALE_PATH}.edit.cardExists`),
    }
  }

  return <TextField
    name={AccessCardViewFields.id}
    label={t(`${CARD_LIST_LOCALE_PATH}.columns.${AccessCardViewFields.id}.headerName`)}
    validator={validateIfCardIdExists}
  />
}
