import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { BoundActions, HookActionsFunction } from 'react-sweet-state';

import { Entity, RestActions, RestActionType, RestResponseModel, RestStoreState } from '@intelliarts/ia-react-template';

import { AccessCardView } from '../components/AccessCards/models/AccessCard';
import { User } from '../components/User/models/User';
import { UserView } from '../components/User/models/UserView';

export const areEntitiesEmpty = (entities: User[] | UserView[] | AccessCardView[]) => {
  return entities === null || (Array.isArray(entities) && entities.length === 0);
}

/**
 * Column's `headerName` is used as default item of the column
 */
export const useTranslateDataGridColumn = (rootListLocalePath: string, defaultItem = 'headerName') => {
  const { t } = useTranslation();

  return useCallback(
    (
      val: string,
      args = {},
      columnItem = defaultItem,
    ) => {
      return t(`${rootListLocalePath}.columns.${val}.${columnItem}`, args);
    },
    [ t, defaultItem ]);
};

export const useActionWithSuccessMessage = <T, E extends Entity = T extends Entity ? T : never>(
  useActions: HookActionsFunction<BoundActions<RestStoreState<E>, RestActions<T, E>>>,
  actionName: RestActionType.PUT | RestActionType.POST | RestActionType.DELETE = RestActionType.PUT,
) => {
  const actions = useActions();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (payload: T) => actions[actionName](payload)
    .then((res: RestResponseModel<T>) => {
      if (!res.error) {
        enqueueSnackbar(t('template.src.notification.success'), { variant: 'success' });
      }
      return res;
    });
};
