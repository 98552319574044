import { StringObject } from '@intelliarts/ia-react-template';

import users from '../../components/User/locales/usersLocale-ua';
import accessCards from '../../components/AccessCards/locales/accessCardsLocale-ua';

const localizationUA: StringObject = {
  template: {
    src: {
      components: {
        layout: {
          header: {
            appName: 'Користувачі'
          }
        },
        dialog: {
          cancelButton: 'Відмінити'
        },
        error: {
          default: {
            defaultErrorMessage: 'Щось пішло не так, спробуйте ще раз'
          }
        }
      },
    },
  },
  /********************************************/
  src: {
    components: {
      pages: {
        settingsPage: {
          language: {
            heading: 'Мова',
            options: {
              uk: 'Солов\'їна',
              en: 'Кредитуюча'
            }
          }
        },
        notFoundPage: {
          message: 'Перепрошую, ви часом не бачили цю сторінку? \n Ми її здається загубили.. \n' +
            'Якщо побачите - пищіть у #under-the-carpet'
        },
      },
      users,
      accessCards,
    },
    /********************************************/
    config: {
      routesConfigs: {
        notFoundPageConfig: {
          name: 'Їжачооок, ти де?'
        },
        settingsPageConfig: {
          name: 'Налаштування',
          label: 'Налаштування'
        },
      },
    },
    /********************************************/
  }
};

export default localizationUA;
