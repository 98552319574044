import { UserViewFields } from '../models/UserView';

export default {
  pageConfig: {
    name: 'Users',
    label: 'Users',
  },
  userInfo: {
    updateGoogleUsersButton: {
      title: 'Upload users from IA Google workspace'
    },
    syncUsers: {
      title: 'Synchronize users on dependent services'
    }
  },
  usersList: {
    columns: {
      [UserViewFields.id]: {
        headerName: 'ID'
      },
      [UserViewFields.fullName]: {
        headerName: 'Full Name'
      },
      [UserViewFields.givenName]: {
        headerName: 'Given Name'
      },
      [UserViewFields.familyName]: {
        headerName: 'Family Name'
      },
      [UserViewFields.email]: {
        headerName: 'Email'
      },
      [UserViewFields.photo]: {
        headerName: 'Photo'
      },
      [UserViewFields.isArchived]: {
        headerName: 'Archived'
      },
      [UserViewFields.isRemote]: {
        headerName: 'Remote'
      },
      [UserViewFields.isServiceAccount]: {
        headerName: 'Service Account'
      },
    }
  }
};
