import { AuthConfig } from '@intelliarts/ia-react-template';

export const NO_NAME_ADMIN_MENU_GROUP = '';
export const NO_NAME_OPEN_MENU_GROUP = ' ';

export const USER = 'ROLE_USER';
export const ADMIN = 'ROLE_ADMIN';

const authConfig = new AuthConfig({
  profileUrl: 'users/me',
  roles: {
    USER,
    ADMIN,
    ALL: 'all',
  }
});

export default authConfig;
