import { lazy } from 'react';

import { MenuItemConfig, ReactPageConfig } from '@intelliarts/ia-react-template';

import authConfig, { NO_NAME_OPEN_MENU_GROUP } from '../authConfig';

export const SettingsPageConfig: ReactPageConfig = {
  name: 'src.config.routesConfigs.settingsPageConfig.name',
  path: 'settings',
  component: lazy(() => import('../../components/pages/SettingsPage')),
  allowedRoles: authConfig.roles.ALL,
  storeDependencies: [],
  menuItemConfig: new MenuItemConfig({
    icon: lazy(() => import('@mui/icons-material/Settings')),
    label: 'src.config.routesConfigs.settingsPageConfig.label',
    group: NO_NAME_OPEN_MENU_GROUP,
  })
};
