import { UserViewFields } from '../models/UserView';

export default {
  pageConfig: {
    name: 'Інтелліартсівці',
    label: 'Інтелліартсівці',
  },
  userInfo: {
    updateGoogleUsersButton: {
      title: 'Завантажити юзерів з IA Гугл воркспейса'
    },
    syncUsers: {
      title: 'Синхронізувати користувачів на залежних сервісах'
    }
  },
  usersList: {
    columns: {
      [UserViewFields.id]: {
        headerName: 'ID'
      },
      [UserViewFields.fullName]: {
        headerName: 'Прізвище та ім\'я'
      },
      [UserViewFields.givenName]: {
        headerName: 'Ім\'я'
      },
      [UserViewFields.familyName]: {
        headerName: 'Прізвище'
      },
      [UserViewFields.email]: {
        headerName: 'Пошта'
      },
      [UserViewFields.photo]: {
        headerName: 'Образ'
      },
      [UserViewFields.isArchived]: {
        headerName: 'Архівований'
      },
      [UserViewFields.isRemote]: {
        headerName: 'Віддалений'
      },
      [UserViewFields.isServiceAccount]: {
        headerName: 'Сервісний акаунт'
      },
    }
  }
};
