export type AccessCard = {
  id: string,
  userId?: number,
  comment?: string,
  isActive: boolean,
  isReportingEnabled: boolean,
};

export type AccessCardView = Omit<AccessCard, 'userId'> & { fullName: string, userId: number | null };

export enum AccessCardViewFields {
  id = 'id',
  userId = 'userId',
  fullName = 'fullName',
  comment = 'comment',
  isActive = 'isActive',
  isReportingEnabled = 'isReportingEnabled',
  editAccessCard = 'editAccessCard',
  deleteAccessCard = 'deleteAccessCard'
}
